import i18n from '@/lang'

/**
 * 周
 */
export function weeks() {
    return {
        '1': i18n.t('周一'),
        '1-2': `${i18n.t('周一')}~${i18n.t('周二')}`,
        '1-3': `${i18n.t('周一')}~${i18n.t('周三')}`,
        '1-4': `${i18n.t('周一')}~${i18n.t('周四')}`,
        '1-5': `${i18n.t('周一')}~${i18n.t('周五')}`,
        '1-6': `${i18n.t('周一')}~${i18n.t('周六')}`,
        '1-7': `${i18n.t('周一')}~${i18n.t('周日')}`,
        '2': i18n.t('周二'),
        '2-3': `${i18n.t('周二')}~${i18n.t('周三')}`,
        '2-4': `${i18n.t('周二')}~${i18n.t('周四')}`,
        '2-5': `${i18n.t('周二')}~${i18n.t('周五')}`,
        '2-6': `${i18n.t('周二')}~${i18n.t('周六')}`,
        '2-7': `${i18n.t('周二')}~${i18n.t('周日')}`,
        '3': i18n.t('周三'),
        '3-4': `${i18n.t('周三')}~${i18n.t('周四')}`,
        '3-5': `${i18n.t('周三')}~${i18n.t('周五')}`,
        '3-6': `${i18n.t('周三')}~${i18n.t('周六')}`,
        '3-7': `${i18n.t('周三')}~${i18n.t('周日')}`,
        '4': i18n.t('周四'),
        '4-5': `${i18n.t('周四')}~${i18n.t('周五')}`,
        '4-6': `${i18n.t('周四')}~${i18n.t('周六')}`,
        '4-7': `${i18n.t('周四')}~${i18n.t('周日')}`,
        '5': i18n.t('周五'),
        '5-6': `${i18n.t('周五')}~${i18n.t('周六')}`,
        '5-7': `${i18n.t('周五')}~${i18n.t('周日')}`,
        '6': i18n.t('周六'),
        '6-7': `${i18n.t('周六')}~${i18n.t('周日')}`,
        '7': i18n.t('周日'),
        '10': i18n.t('节假日'),
        '11': i18n.t('新年假日'),
        '12': i18n.t('除夕'),
        '13': i18n.t('元旦'),
        '14': i18n.t('依照餐厅营业时间为准'),
        '15': i18n.t('节假日前')
    }
}


/**
 * 餐厅服务
 */
export function storeService() {
    return [
        { value: '1', label: 'WIFI', icon: require('@/assets/images/wifi.png') },
        { value: '2', label: i18n.t('停车场'), icon: require('@/assets/images/parking.png') },
        { value: '3', label: i18n.t('电话充电'), icon: require('@/assets/images/phone.png') },
        { value: '4', label: i18n.t('寄存行李'), icon: require('@/assets/images/wallet.png') },
        { value: '5', label: i18n.t('婴儿座椅'), icon: require('@/assets/images/seat.png') },
        { value: '6', label: i18n.t('厕所'), icon: require('@/assets/images/toilet.png') },
        { value: '7', label: i18n.t('空调'), icon: require('@/assets/images/air.png') },
    ]
}

// 白色底
export function storeService2() {
    return [
        { value: '1', label: 'WIFI', icon: require('@/assets/images/wifi-02.png') },
        { value: '2', label: i18n.t('停车场'), icon: require('@/assets/images/parking-02.png') },
        { value: '3', label: i18n.t('电话充电'), icon: require('@/assets/images/phone-02.png') },
        { value: '4', label: i18n.t('寄存行李'), icon: require('@/assets/images/wallet-02.png') },
        { value: '5', label: i18n.t('婴儿座椅'), icon: require('@/assets/images/seat-02.png') },
        { value: '6', label: i18n.t('厕所'), icon: require('@/assets/images/toilet-02.png') },
        { value: '7', label: i18n.t('空调'), icon: require('@/assets/images/air-02.png') },
    ]
}

/**
 * 餐厅类型
 */
export function storeShopType() {
    return {
        '2': i18n.t('日本料理'),
        '3': i18n.t('寿司'),
        '4': i18n.t('海鲜料理'),
        '5': i18n.t('荞麦・乌龙面'),
        '11': i18n.t('天妇罗'),
        '59': i18n.t('日式甜点'),
        '15': i18n.t('冲绳料理'),
        '17': i18n.t('法式料理'),
        '46': i18n.t('义式料理'),
        '19': i18n.t('西班牙料理'),
        '25': i18n.t('中华料理'),
        '27': i18n.t('韩国料理'),
        '28': i18n.t('泰国料理'),
        '47': i18n.t('东南亚料理'),
        '44': i18n.t('创作料理'),
        '49': i18n.t('异国料理'),
        '50': i18n.t('美式料理'),
        '43': i18n.t('铁板料理'),
        '56': i18n.t('清真料理'),
        '57': i18n.t('僧侣料理'),
        '67': i18n.t('无国籍料理'),
        '68': i18n.t('多国籍料理'),
        '35': i18n.t('居酒屋'),
        '36': i18n.t('自助餐'),
        '37': i18n.t('咖啡店'),
        '12': i18n.t('大阪烧'),
        '60': i18n.t('章魚燒'),
        '61': i18n.t('肉料理'),
        '62': i18n.t('寿喜烧'),
        '63': i18n.t('吃到饱'),
        '69': i18n.t('百货店'),
        '22': i18n.t('牛排'),
        '51': i18n.t('和食'),
        '52': i18n.t('洋食'),
        '53': i18n.t('麵食'),
        '54': i18n.t('轻食'),
        '30': i18n.t('咖喱'),
        '31': i18n.t('烧肉'),
        '33': i18n.t('火锅'),
        '39': i18n.t('甜点'),
        '41': i18n.t('酒吧'),
        '55': i18n.t('排餐'),
        '58': i18n.t('丼飯'),
        '64': i18n.t('串炸'),
        '65': i18n.t('饺子'),
        '66': i18n.t('串烧'),
    }
}

// 付款方式
export function storePayType() {
    return {
        '1': 'VISA',
        '2': i18n.t('Master'),
        '3': i18n.t('American Express'),
        '4': 'DINERS',
        '5': 'JCB',
        '6': 'Discover',
        '7': i18n.t('银联'),
        '8': i18n.t('电子支付'),
        '9': i18n.t('二维码支付'),
        '10': i18n.t('现金支付'),
    }
}

// 电子支付方式
export function onlinePayType() {
    return {
        '1': '楽天Edy',
        '2': 'nanaco',
        '3': 'WAON',
        '4': 'Suica',
        '5': 'PASMO',
        '6': 'QUICPay',
        '7': 'iD',
        '8': 'ApplePay',
        '9': 'ICOCA',
        '10': 'PiTaPa'
    }
}

// 二维码支付方式
export function qrPayType() {
    return {
        '1': 'PayPay',
        '2': 'LINE Pay',
        '3': '楽天ペイ',
        '4': 'ｄ払い',
        '5': 'au PAY',
        '6': 'メルペイ ',
        '7': 'Amazon Pay',
        '8': 'pring',
        '9': 'ANA Pay',
        '10': 'Pay ID',
        '11': 'Smash Pay',
        '12': 'EPOS Pay',
        '13': '＆Pay',
        '14': 'atone',
        '15': 'QUOカードPay',
        '16': 'J-Coin Pay',
        '17': 'BANK Pay',
        '18': 'FamiPay',
        '19': 'ALI Pay',
        '20': 'WECHAT Pay'
    }
}

// 疫情措施
export function measuresList() {
    return [
        { value: '1', label: i18n.t('员工有带口罩') },
        { value: '2', label: i18n.t('进店有酒精消毒') },
        { value: '4', label: i18n.t('客人必须带口罩') },
        { value: '5', label: i18n.t('有挡板设置') },
        { value: '6', label: i18n.t('换气对策') },
        { value: '8', label: i18n.t('间隔设置') },
        { value: '10', label: i18n.t('空气处理器配置') },
        { value: '11', label: i18n.t('防止感染宣言贴纸') },
        { value: '13', label: i18n.t('員工測量體溫') },
        { value: '14', label: i18n.t('客人測量體溫') },
        { value: '15', label: i18n.t('洗手・手指消毒') },
        { value: '16', label: i18n.t('店內清潔・消毒') },
        { value: '17', label: i18n.t('CO2 感测器') },
        { value: '12', label: i18n.t('其他') }
    ]
}

// 价格列表
var priceList = [
    { value: 500, label: '500' },
    { value: 1000, label: '1,000' },
    { value: 1500, label: '1,500' },
    { value: 2000, label: '2,000' },
    { value: 3000, label: '3,000' },
    { value: 4000, label: '4,000' },
    { value: 5000, label: '5,000' },
    { value: 6000, label: '6,000' },
    { value: 7000, label: '7,000' },
    { value: 8000, label: '8,000' },
    { value: 9000, label: '9,000' },
    { value: 10000, label: '10,000' },
    { value: 12000, label: '12,000' },
    { value: 15000, label: '15,000' },
    { value: 20000, label: '20,000' },
    { value: 30000, label: '30,000' },
    { value: 40000, label: '40,000' },
    { value: 50000, label: '50,000' },
    { value: 60000, label: '60,000' },
    { value: 70000, label: '70,000' },
    { value: 80000, label: '80,000' },
    { value: 90000, label: '90,000' },
    { value: 100000, label: '100,000' }
]

/**
 * 价格下限
 */
export function minPriceList() {
    var arr = [{ value: 0, label: i18n.t('无下限') }]
    return arr.concat(priceList)
}

/**
 * 价格上限
 */
export function maxPriceList() {
    var arr = [{ value: 900000, label: i18n.t('无上限') }]
    return priceList.concat(arr)
}

// 首页tab数据
export function homeSortList() {
    return [
        { value: '1', label: this.$t('精选餐厅') }
    ]
}
// 首页banner数据
export function homeBannerList() {
    return [{
            id: 1,
            url: {
                'zh': require("@/assets/images/banner/banner-8.png"),
                'ja': require("@/assets/images/banner/banner-8.png"),
                'en': require("@/assets/images/banner/banner-8.png"),
                'ko': require("@/assets/images/banner/banner-8.png"),
            },
            // path: `/departmentDetail?id=${process.env.VUE_APP_RESTURATN}`
            path: {
                'zh': 'http://www.japan-osaka.cn/osp/e-pass/ch/',
                'en': 'https://www.e-pass.osaka-info.jp/en/',
                'ja': 'https://www.e-pass.osaka-info.jp/jp/',
                'ko': 'https://www.e-pass.osaka-info.jp/kr/',
            }
        },
        {
            id: 11,
            url: {
                'zh': require("@/assets/images/banner/banner-11.png"),
                'en': require("@/assets/images/banner/banner-11.png"),
                'ja': require("@/assets/images/banner/banner-11.png"),
                'ko': require("@/assets/images/banner/banner-11.png"),
            },
            path: '/filmcontest'
        },
        {
            id: 3,
            url: {
                'zh': require("@/assets/images/banner/banner-5-zh.png"),
                'en': require("@/assets/images/banner/banner-5-en.png"),
                'ja': require("@/assets/images/banner/banner-5-ja.png"),
                'ko': require("@/assets/images/banner/banner-5-ko.png"),
            },
            path: '/recommend2'
        },
        {
            id: 2,
            url: {
                'zh': require("@/assets/images/banner/banner-9-zh.png"),
                'ja': require("@/assets/images/banner/banner-9-ja.png"),
                'en': require("@/assets/images/banner/banner-9-en.png"),
                'ko': require("@/assets/images/banner/banner-9-ko.png"),
            },
            path: '/ver'
        },
        {
            id: 4,
            url: {
                'zh': require("@/assets/images/banner/banner-3.png"),
                'en': require("@/assets/images/banner/banner-3.png"),
                'ja': require("@/assets/images/banner/banner-3.png"),
                'ko': require("@/assets/images/banner/banner-3-ko.png"),
            },
            path: '/special'
        },
        {
            id: 5,
            url: {
                'zh': require("@/assets/images/banner/banner-1.png"),
                'ja': require("@/assets/images/banner/banner-1.png"),
                'en': require("@/assets/images/banner/banner-1.png"),
                'ko': require("@/assets/images/banner/banner-1.png"),
            },
            path: '/gototravel'
        },
        {
            id: 6,
            url: {
                'zh': require("@/assets/images/banner/banner-7-zh.png"),
                'ja': require("@/assets/images/banner/banner-7-ja.png"),
                'en': require("@/assets/images/banner/banner-7-en.png"),
                'ko': require("@/assets/images/banner/banner-7-ko.png"),
            },
            path: '/koreanfood'
        },

    ]
}
// 首页手机banner数据
export function homePhBannerList() {
    return [{
            id: 6,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-9.png"),
                'ja': require("@/assets/images/banner/phone/banner-9.png"),
                'en': require("@/assets/images/banner/phone/banner-9.png"),
                'ko': require("@/assets/images/banner/phone/banner-9.png"),
            },
            // path: `/departmentDetail?id=${process.env.VUE_APP_RESTURATN}`
            path: {
                'zh': 'http://www.japan-osaka.cn/osp/e-pass/ch/',
                'en': 'https://www.e-pass.osaka-info.jp/en/',
                'ja': 'https://www.e-pass.osaka-info.jp/jp/',
                'ko': 'https://www.e-pass.osaka-info.jp/kr/',
            }
        },
        {
            id: 12,
            url: {
                'zh': require("@/assets/images/banner/banner-10.png"),
                'en': require("@/assets/images/banner/banner-10.png"),
                'ja': require("@/assets/images/banner/banner-10.png"),
                'ko': require("@/assets/images/banner/banner-10.png"),
            },
            path: '/filmcontest'
        },
        {
            id: 1,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-10-zh.png"),
                'ja': require("@/assets/images/banner/phone/banner-10-ja.png"),
                'en': require("@/assets/images/banner/phone/banner-10-en.png"),
                'ko': require("@/assets/images/banner/phone/banner-10-ko.png"),
            },
            path: '/ver'
        },
        {
            id: 2,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-8.png"),
                'en': require("@/assets/images/banner/phone/banner-8.png"),
                'ja': require("@/assets/images/banner/phone/banner-8.png"),
                'ko': require("@/assets/images/banner/phone/banner-8.png"),
            },
            path: '/promotion'
        },
        {
            id: 3,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-3-zh.png"),
                'en': require("@/assets/images/banner/phone/banner-3-en.png"),
                'ja': require("@/assets/images/banner/phone/banner-3-ja.png"),
                'ko': require("@/assets/images/banner/phone/banner-3-ko.png"),
            },
            path: '/special'
        },
        {
            id: 4,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-1.png"),
                'en': require("@/assets/images/banner/phone/banner-1.png"),
                'ja': require("@/assets/images/banner/phone/banner-1.png"),
                'ko': require("@/assets/images/banner/phone/banner-1.png"),
            },
            path: '/gototravel'
        },
        {
            id: 5,
            url: {
                'zh': require("@/assets/images/banner/phone/banner-7-zh.png"),
                'ja': require("@/assets/images/banner/phone/banner-7-ja.png"),
                'en': require("@/assets/images/banner/phone/banner-7-en.png"),
                'ko': require("@/assets/images/banner/phone/banner-7-ko.png"),
            },
            path: '/koreanfood'
        },
    ]
}
// 首页手机端活动
export function homeActivityList() {
    return [{
        id: 1,
        url: {
            'zh': require("@/assets/images/banner/phone/activity-1-zh.png"),
            'en': require("@/assets/images/banner/phone/activity-1-en.png"),
            'ja': require("@/assets/images/banner/phone/activity-1-ja.png"),
            'ko': require("@/assets/images/banner/phone/activity-1-ko.png"),
        },
        path: '/recommend2'
    }, ]
}

export function homeLanList() {
    return {
        'zh': '简体中文',
        'zh_tw': '繁體中文',
        'en': 'English',
        'ja': '日本語',
        'ko': '한국어',
    }
}

// 公告文
export function Announcement() {
    return [{
        'zh': '请特别留意无法与其他预约网站以及首页的优惠併用。',
        'zh_tw': '請特別留意無法與其他預約網站以及首頁的優惠並用。',
        'en': 'Please note that the offer cannot be combined with offers provided from other booking sites.',
        'ja': '他の予約サイト及び他ホームページ特典との併用はできません。ご注意ください。',
        'ko': '타 예약(WEB)사이트 및 타 홈페이지특전과 함께 사용할 수 없습니다. 주의 부탁드립니다.'
    }]
}

// 公告文1
export function Announcement1() {
    return [{
        'zh': '各餐厅的营业时间可能会有临时变动的情形，请务必于出行前确认营业时间。',
        'zh_tw': '各餐廳的營業時間可能會有臨時變動的情形，請務必于出行前確認營業時間。',
        'en': 'The opening hours of all restaurants may change temporarily, please be sure to confirm the opening hours in advance.',
        'ja': '各レストランの営業時間・定休日等は、予め公式サイトにてご確認ください。',
        'ko': '각 레스토랑의 영업시간, 정기휴일 등은 공식홈페이지에서 확인해 주세요.'
    }]
}
// 公告文2
export function Announcement2() {
    return [{
        'zh': '各设施的营业时间可能会有临时变动的情形，请务必于出行前确认营业时间。',
        'zh_tw': '各餐廳的營業時間可能會有臨時變動的情形，請務必于出行前確認營業時間。',
        'en': 'The opening hours of tourist facilities may change temporarily, please be sure to confirm the opening hours in advance.',
        'ja': '各施設の営業時間・定休日等は、予め公式サイトにてご確認ください。',
        'ko': '각 시설의 영업시간, 정기휴일 등은 공식홈페이지에서 확인해 주세요.'
    }]
}
// 首页合作伙伴数据
export function homePartnerList() {
    return [
        { id: 3, url: require("@/assets/images/partner/p3.png"), title: '', link: 'https://www.usj.co.jp/web/en/us' },
        { id: 1, url: require("@/assets/images/partner/p1.png"), title: '', link: 'https://info.tripodeck.com/allpass' },
        { id: 2, url: require("@/assets/images/partner/p2.png"), title: '', link: 'https://www.osp.osaka-info.jp/e-pass/jp/' },
        { id: 4, url: require("@/assets/images/partner/p5.png"), title: '', link: 'https://affiliate.klook.com/redirect?aid=18588&label1=website&label2=campaign-LP&label3=&k_site=https%3A%2F%2Fwww.klook.com%2Fja%2Factivity%2F53087%2F%3Faid%3D18588' },
        { id: 5, url: require("@/assets/images/partner/p4.png"), title: '', link: 'https://www.anha.or.jp/' },
        { id: 6, url: require("@/assets/images/partner/p6.png"), title: '', link: 'https://minpaku.gich.net/' },
    ]
}
export function homeMediaList() {
    return [
        { id: 4, url: require("@/assets/images/footer/media-4.png"), title: '', target: '_self', link: '/aboutus' },
        { id: 1, url: require("@/assets/images/footer/media-3.png"), title: '', target: '_blank', link: 'https://www.instagram.com/tripellet/' },
        { id: 2, url: require("@/assets/images/footer/media-1.png"), title: '', target: '_blank', link: 'https://www.facebook.com/tripellet.mkt' },
        { id: 3, url: require("@/assets/images/footer/media-2.png"), title: '', target: '_blank', link: 'https://www.youtube.com/channel/UCYbaKc7Ez8dqhSWHJsXhR6g' },
        // { id: 5, url: require("@/assets/images/footer/media-5.png"), title: '', target: '_blank', link: 'https://blog.naver.com/tripellet' },
    ]
}

// 首页公告
export function homeBulletinList() {
    return [{
            content: {
                'ja': 'まん延防止要請に応じて営業時間の変更や短縮する場合がございます。',
                'ko': '확산방지요청에 따라 영업시간 변경 및 단축될 수 있습니다. 각 레스토랑의 영업시간, 정기휴일 등은 공식홈페이지에서 확인해 주세요.',
                'zh': '因應防止疫情蔓延措施，店家營業時間可能變更或縮短。',
                'en': 'Due to the measure of preventing COVID-19 from spreading, the business hours of the restaurants may be changed or be shortened.',
            }
        },
        {
            content: {
                'ja': '各レストラン の営業時間・定休日等は、予め公式サイトにてご確認ください。',
                'ko': '신형 코로나바이러스 감염확대로 인하여 영업시간,휴관일이 기재된 내용과 다를 수 있습니다. 방문시에는 사전에 점포로부터 확인 부탁드립니다.',
                'zh': '因应新冠肺炎，各餐厅的营业时间可能会有临时变动的情形，请务必於出行前确认营业时间。',
                'en': 'Due to COVID-19, the opening hours of all restaurants may change temporarily, please be sure to confirm the opening hours in advance.',
            }
        },
        {
            content: {
                'ja': '他の予約サイト及び他ホームページ特典との併用はできません、ご注意ください。',
                'ko': '타 예약(WEB)사이트 및 타 홈페이지특전과 함께 사용할 수 없습니다. 주의 부탁드립니다.',
                'zh': '请特别留意无法与其他预约网站以及首页的优惠併用。',
                'en': 'Please note that the offer cannot be combined with offers provided from other booking sites.',
            },
            color: 'red'
        },
    ]
}

export function aboutBannerList() {
    return {
        'zh': require('@/assets/images/aboutus/app_zh.jpg'),
        'zh_tw': require('@/assets/images/aboutus/app_tw.jpg'),
        'ko': require('@/assets/images/aboutus/app_ko.jpg'),
        'ja': require('@/assets/images/aboutus/app_jp.jpg'),
        'en': require('@/assets/images/aboutus/app_en.jpg'),
    }
}
// 下载页pc图片
export function downloadPageA_list_PC() {
    return {
        'zh': require('@/assets/images/downloadPage/A/CH_A.png'),
        'ko': require('@/assets/images/downloadPage/A/KR_A.jpg'),
        'zh_tw': require('@/assets/images/downloadPage/A/TW_A.jpg'),
        'en': require('@/assets/images/downloadPage/A/EN_A.jpg'),
        'ja': require('@/assets/images/downloadPage/A/EN_A.jpg'),
    }
}

export function downloadPageA_list_MB() {
    return {
        'zh': require('@/assets/images/downloadPage/A/CH_B.png'),
        'ko': require('@/assets/images/downloadPage/A/KR_B.png'),
        'zh_tw': require('@/assets/images/downloadPage/A/TW_B.png'),
        'en': require('@/assets/images/downloadPage/A/EN_B.png'),
        'ja': require('@/assets/images/downloadPage/A/EN_B.png'),
    }
}
// 下载页pc图片
export function downloadPageB_list_PC() {
    return {
        'zh': require('@/assets/images/downloadPage/B/CH_A.jpg'),
        'ko': require('@/assets/images/downloadPage/B/KR_A.jpg'),
        'zh_tw': require('@/assets/images/downloadPage/B/TW_A.png'),
        'en': require('@/assets/images/downloadPage/B/EN_A.png'),
        'ja': require('@/assets/images/downloadPage/B/EN_A.jpg'),
    }
}
export function downloadPageB_list_MB() {
    return {
        'zh': require('@/assets/images/downloadPage/B/CH_B.png'),
        'ko': require('@/assets/images/downloadPage/B/KR_B.png'),
        'zh_tw': require('@/assets/images/downloadPage/B/TW_B.png'),
        'en': require('@/assets/images/downloadPage/B/EN_B.png'),
        'ja': require('@/assets/images/downloadPage/B/EN_B.png'),
    }
}

/*
  首页视频链接
  src: 视频图片格式为 'http://i.ytimg.com/vi/视频码/default.jpg'
  url: 视频链接格式为 'https://www.youtube.com/embed/视频码'
*/
export function homeVideoList() {
    return [
        { src: `http://i.ytimg.com/vi/zdIxgGyB3DM/default.jpg`, url: 'https://www.youtube.com/embed/zdIxgGyB3DM' },
        { src: `http://i.ytimg.com/vi/AHZU5voUQIU/default.jpg`, url: 'https://www.youtube.com/embed/AHZU5voUQIU' },
        { src: 'http://i.ytimg.com/vi/czjV4S-JaUU/default.jpg', url: 'https://www.youtube.com/embed/czjV4S-JaUU' },
        { src: 'http://i.ytimg.com/vi/SVJcW_s6DvE/default.jpg', url: 'https://www.youtube.com/embed/SVJcW_s6DvE' },
        { src: 'http://i.ytimg.com/vi/I9hcSbjx6aQ/default.jpg', url: 'https://www.youtube.com/embed/I9hcSbjx6aQ' },
        { src: 'http://i.ytimg.com/vi/VwaDQVEFV34/default.jpg', url: 'https://www.youtube.com/embed/VwaDQVEFV34' },
        { src: 'http://i.ytimg.com/vi/7i3VWDMCabc/default.jpg', url: 'https://www.youtube.com/embed/7i3VWDMCabc' },
    ]
}
// 企业公告 mb
export function companyAnno() {
    return [{
        header: {
            'zh': require('@/assets/images/announcement/ZH_H.png'),
            'zh_tw': require('@/assets/images/announcement/TW_H.png'),
            'ko': require('@/assets/images/announcement/KO_H.png'),
            'ja': require('@/assets/images/announcement/JP_H.png'),
            'en': require('@/assets/images/announcement/EN_H.png'),
        },
        main: {
            'zh': require('@/assets/images/announcement/ZH_M.png'),
            'zh_tw': require('@/assets/images/announcement/TW_M.png'),
            'ko': require('@/assets/images/announcement/KO_M.png'),
            'ja': require('@/assets/images/announcement/JP_M.png'),
            'en': require('@/assets/images/announcement/EN_M.png'),
        },
        footer: {
            'zh': require('@/assets/images/announcement/ZH_F.png'),
            'zh_tw': require('@/assets/images/announcement/TW_F.png'),
            'ko': require('@/assets/images/announcement/KO_F.png'),
            'ja': require('@/assets/images/announcement/JP_F.png'),
            'en': require('@/assets/images/announcement/EN_F.png'),
        },
        href: {
            'zh': 'https://www.bilibili.com/video/BV16L4y157J9?share_source=copy_web',
            'zh_tw': 'https://youtu.be/B1C2LwUDBCo',
            'ko': 'https://youtu.be/HUrUkKvcRiw',
            'ja': 'https://www.youtube.com/watch?v=3PFiYv4tTDs',
            'en': 'https://www.youtube.com/watch?v=3PFiYv4tTDs',
        }
    }]
}
// 企业公告 pc
export function companyAnno2() {
    return [{
        id: 1,
        url: {
            'zh': require("@/assets/images/announcement/ch.png"),
            'zh_tw': require("@/assets/images/announcement/tw.png"),
            'en': require("@/assets/images/announcement/en.png"),
            'ja': require("@/assets/images/announcement/en.png"),
            'ko': require("@/assets/images/announcement/kr.png"),
        },
        title: {
            'zh_tw': '【Travel Contents APP】 推薦給訪日外國人，新誕生的日本觀光超方便APP！',
            'zh': '【Travel Contents APP】 推荐给访日外国人，新诞生的日本观光超方便APP！',
            'en': '[Travel Contents APP] For foreigners visiting Japan, a super convenient Japan sightseeing APP has been launched!',
            'ja': '【Travel Contents APP】 訪日外国人向け、便利に使える日本観光アプリが誕生！',
            'ko': '[Travel Contents APP] 방일외국인을 위한 편리한 일본관광어플 탄생!',
        },
        date: {
            'zh': '2022 / 04 / 20',
            'zh_tw': '2022 / 04 / 20',
            'en': '2022 / 04 / 20',
            'ja': '2022 / 04 / 20',
            'ko': '2022 / 04 / 20',
        },
        detail: {
            'zh': '了解详情',
            'zh_tw': '了解詳情',
            'en': 'Detail',
            'ja': '詳細を知る',
            'ko': '자세히 보기',
        },
    }, ]
}
// 企业公告详情
export function companyAnnoItem() {
    return {
        'zh': require('@/assets/images/announcement/TRAVEL-SC.jpg'),
        'zh_tw': require('@/assets/images/announcement/TRAVEL-TC.jpg'),
        'ko': require('@/assets/images/announcement/TRAVEL-KR.jpg'),
        'ja': require('@/assets/images/announcement/TRAVEL-JP.jpg'),
        'en': require('@/assets/images/announcement/TRAVEL-EN.jpg'),
    }

}
export function homeRestIds() {
    return process.env.VUE_APP_RESTURATN_ID
}

/**
 * 返回默认的经度
 *
 * @returns
 */
export function getDefaultLon() {
    return 135.4959666
}

/**
 * 返回默认的纬度
 * @returns
 */
export function getDefaultLat() {
    return 34.7025119
}